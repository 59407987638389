import React, {useMemo} from 'react';
import { useController } from 'react-hook-form';
import { DatePicker } from '../../../../ui';

export const FormDatePicker = ({ form, ...props }) => {
  const {
    field,
    fieldState: { error }
  } = useController({
    ...form,
    defaultValue: ''
  });

  const { value, ...otherField } = field;

  const formatValue = useMemo(() => {
    try {
      return value && typeof value === 'string' ? new Date(value) : value
    } catch (e) {
      return null;
    }
  }, [value]);


  return (
    <DatePicker
      selected={formatValue}
      error={error}
      showYearDropdown
      {...otherField}
      {...props}
    />
  );
};
