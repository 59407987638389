import React from 'react';
import { Bar, BackButton } from '@aragon/ui';
import { Body2Light } from '../../../../ui';
import { history } from '../../../../routing';

export const BackBar = ({ url = null }) => {
  const onBack = () => {
    url ? history.push(url) : history.goBack();
  };

  return <Bar primary={<BackButton label={<Body2Light>Back</Body2Light>} onClick={onBack} />} />;
};
