import { createSlice } from '@reduxjs/toolkit';

let initialState = {
  deepKYCStatus: null,
  sanctionsStatus: null,
  lastProfileTab: 0,
  additionalInfo: null
};

const investor = createSlice({
  name: 'investorReducer',
  initialState,
  reducers: {
    resetInvestor(state, action) {
      state = initialState;
    },
    setInvestor(state, action) {
      for (const [key, value] of Object.entries(action.payload)) {
        state[key] = value;
      }
    },
    setVerifiedEmail(state, action) {
      state.verifiedEmail = action.payload;
    },
    setVerifiedPhone(state, action) {
      state.verifiedPhone = action.payload;
    },
    setEthereumAddress(state, action) {
      state.ethereumAddress = action.payload;
    },
    setAddresses(state, action) {
      state.addresses = action.payload;
    },
    setDocuments(state, action) {
      state.documents = action.payload;
    },
    setCashSummary(state, action) {
      state.cashSummary = action.payload;
    },
    setCitizenship(state, action) {
      state.citizenship = action.payload;
    },
    setAdditionalInfo(state, action) {
      state.additionalInfo = action.payload;
    },
    setDeepKYCStatus(state, action) {
      state.deepKYCStatus = action.payload;
    },
    setSanctionsStatus(state, action) {
      state.sanctionsStatus = action.payload;
    },
    setLastProfileTab(state, action) {
      state.lastProfileTab = action.payload;
    }
  }
});

export const {
  resetInvestor,
  setInvestor,
  setVerifiedEmail,
  setVerifiedPhone,
  setEthereumAddress,
  setAddresses,
  setDocuments,
  setCashSummary,
  setCitizenship,
  setAdditionalInfo,
  setDeepKYCStatus,
  setSanctionsStatus,
  setLastProfileTab
} = investor.actions;

export default investor.reducer;
