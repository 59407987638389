import BrokerAvatar from '../../../assets/icons/temp/broker_avatar.png';

export const CURRENCIES = ['EUR'];

export const CURRENCIES_TYPES = {
  EUR: 'EUR'
};

export const CURRENCY_SYMBOL = {
  EUR: '€'
};

export const BROKER_PROFILE = {
  name: 'STO.Broker',
  avatar: BrokerAvatar,
  region: 'LT',
  verified: true
};

export const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const PASSWORD_REGEX = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z\-\_\.]{10,}$/;
