import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getCountries } from '../modules/kycModule/serverInteraction';
import { entitiesActions } from '../store/entities';

export const useCountries = () => {
  const dispatch = useDispatch();
  const { countries } = useSelector((state) => state.entities);

  useEffect(() => {
    (async () => {
      if (!countries) {
        try {
          const resCountries = await getCountries();
          dispatch(entitiesActions.setCountries(resCountries.data));
        } catch (e) {
          console.error('FETCH_COUNTRIES', e);
        }
      }
    })();
  }, []);

  return countries;
};
