import React from 'react';
import { GU, Text } from '@aragon/ui';
import {
  Box,
  BoxLine,
  InnerBox,
  StatusLine,
  ProfitLine,
  DirectionBadge
} from '../../../../components';
import { Row, Col, Title5 } from '../../../../ui';
import {
  COLORS,
  DEAL_STATUSES,
  EXECUTION_PLACE,
  ASSET_TYPES,
  BUY_ASSET_FEE_PERCENT
} from '../../../../utils/static';
import { displayFullDate } from '../../../../utils/helpers';

export const DealDetailsBox = ({ data = null }) => {
  if (data) {
    const {
      totalPrice,
      asset,
      tradeDate,
      updatedAt,
      status,
      fee,
      id,
      quantity,
      pricePerCapita,
      settlementType,
      assetId,
      side
    } = data;

    return (
      <Box padding={0}>
        <InnerBox paddingY={2 * GU}>
          <Row alignItems={'center'} margin={-0.5 * GU} marginBottom={GU}>
            <Title5>
              {totalPrice} {asset.currency}
            </Title5>

            <Col auto>
              <DirectionBadge title={side} />
            </Col>
          </Row>

          <StatusLine statusTypes={DEAL_STATUSES} type={status} marginBottom={2 * GU} />

          <BoxLine title={'Fee'} data={`${BUY_ASSET_FEE_PERCENT}%`} />
          <BoxLine>
            <Text color={COLORS.greyMedium}>
              {fee} {asset.currency}
            </Text>
          </BoxLine>
        </InnerBox>

        <InnerBox>
          <BoxLine title={'Deal ID'} data={id} />
          <BoxLine title={'Trade date'} data={displayFullDate(new Date(tradeDate))} />
          <BoxLine title={'Settlement date'} data={displayFullDate(new Date(updatedAt))} />
          <BoxLine title={'Quantity'} data={quantity} />
          <BoxLine title={'Price per capita'} data={pricePerCapita} />
          {/*<BoxLine title={'Profit/Loss'}>*/}
          {/*<ProfitLine profit={profit} profitPercent={profitPercent}/>*/}
          {/*</BoxLine>*/}
          <BoxLine title={'Settlement type'} data={settlementType} />
          <BoxLine title={'Execution place'} data={EXECUTION_PLACE} />
        </InnerBox>

        <InnerBox>
          <BoxLine title={'Asset ID'} data={assetId} />
          <BoxLine
            title={'Asset Type'}
            data={<Text color={COLORS.aragonBlue}>{ASSET_TYPES[asset.assetType]}</Text>}
          />
          <BoxLine title={'ISIN'} data={asset.isin} />
        </InnerBox>
      </Box>
    );
  }

  return null;
};
