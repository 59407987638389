import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {IconClose} from "@aragon/ui";
import {Relative} from "../../../../ui/layout";
import {COLORS} from "../../../../utils/static/colors";


const TRANSITION = 200;
export const Overlay = ({visible, onClose, children}) => {
  const [domVisible, setDomVisible] = useState(true);

  useEffect(() => {
    if (visible !== domVisible) {
      if (visible) {
        setDomVisible(visible);
      } else {
        setTimeout(() => {
          setDomVisible(visible);
        }, TRANSITION * 1.25);
      }
    }
  }, [visible, domVisible]);

  return (
    <OverlayWrapper visible={visible} display={visible || domVisible}>
      <Relative padding={24}>
        {children}

        <CloseButton onClick={onClose}>
          <IconClose/>
        </CloseButton>
      </Relative>
    </OverlayWrapper>
  )
};


const OverlayWrapper = styled.div`
  position: fixed;
  left: 50%;
  bottom: 32px;
  transform: translate(-50%, 0);
  z-index: 999;
  box-shadow: 0 10px 28px rgb(0 0 0 / 15%);
  border-radius: 4px;
  background: ${COLORS.white};
  transition: ${TRANSITION}ms ease-in-out;
  ${p => p.visible ? `
    opacity: 1;
    visibility: visible;
  ` : `
    opacity: 0;
    visibility: hidden;
  `}
  ${p => !p.display && 'display: none;'}
  
  @media (max-width: 1200px) {
    width: 100%;
    max-width: 70%;
  }
`;
const CloseButton = styled.div`
  position: absolute;
  right: 12px;
  top: 12px;
  cursor: pointer;
  > svg {
    display: block;
  }
`;
