import React from 'react';
import styled from 'styled-components';
import { GU, IconView as IconViewAragon } from '@aragon/ui';

export const IconView = ({ onClick }) => {
  return (
    <IconViewWrapper onClick={onClick}>
      <IconViewAragon />
    </IconViewWrapper>
  );
};

const IconCheckWrapper = styled.div`
  width: ${4 * GU}px;
  height: ${4 * GU}px;
  padding: 5px;
  background: #2cc68f;
  color: #ffffff;
  border-radius: 100%;
`;
const IconViewWrapper = styled(IconCheckWrapper)`
  padding: 3px;
  background: #ffffff;
  mix-blend-mode: normal;
  border: 1px solid #dde4e9;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  color: #08bee5;
  cursor: pointer;
`;
