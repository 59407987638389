import React, { useMemo } from 'react';
import { GU, useTheme, CircleGraph } from '@aragon/ui';
import ConfigureStepsItem from './ConfigureStepItem';
import { Title1, Title3 } from '../../../ui';
import textStyle from '../../../utils/textStyle';
import styled from 'styled-components';

function StepsPanel({ step, steps, title, width = 224, setStep }) {
  const theme = useTheme();
  step = step - 1;

  // Mark identical siblings to only show the last step
  const [groupedSteps, displayedSteps] = useMemo(() => {
    // these get updated by the .map() to avoid another iteration
    let displayCount = 0;

    const groupedSteps = steps.map((step, index) => {
      const hiddenCount = index - displayCount;

      if (step !== steps[index + 1]) {
        displayCount++;
        return [index, index - hiddenCount, true];
      }

      let statusIndex = index;
      while (step === steps[statusIndex + 1] && statusIndex < steps.length) {
        statusIndex++;
      }

      return [
        // The index used for the status in the panel (last of the group)
        statusIndex,
        // The index used for the display in the panel (first of the group)
        index - hiddenCount,
        // Do not display the step
        false
      ];
    });

    return [groupedSteps, displayCount];
  }, [steps]);

  return (
    <StepPanelContainer theme={theme} width={width}>
      <Title3 marginBottom={2 * GU} textAlign={'center'}>
        {title}
      </Title3>

      <CircleGraphContainer>
        <CircleGraph value={groupedSteps[step][1] / displayedSteps} size={162} />
        <CircleGraphStepsCounter>
          {`${groupedSteps[step][1] + 1}/${displayedSteps}`}
        </CircleGraphStepsCounter>
      </CircleGraphContainer>

      <StepsContainer>
        {groupedSteps.map(
          ([statusIndex, displayIndex, show], index) =>
            show && (
              <ConfigureStepsItem
                key={index}
                currentStep={groupedSteps[step][1]}
                label={steps[statusIndex]}
                step={statusIndex}
                stepNumber={displayIndex + 1}
                onClick={setStep ? () => setStep(displayIndex) : null}
              />
            )
        )}
      </StepsContainer>
    </StepPanelContainer>
  );
}

export default StepsPanel;

const StepPanelContainer = styled.aside`
  width: 100%;
  min-height: 100%;
  padding-top: ${5 * GU}px;
  background: ${(p) => p.theme.surface};
  box-shadow: 2px 0px 3px rgba(0, 0, 0, 0.05);
  max-width: ${(p) => (p.width ? `${p.width}px` : '224px')};
  min-width: ${(p) => (p.width ? `${p.width}px` : '224px')};
`;
const Title = styled.h3`
  margin: 0 0 ${2 * GU}px 0;
  text-align: center;
`;
const CircleGraphContainer = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  justify-content: center;
  height: 162px;
`;
const CircleGraphStepsCounter = styled.p`
  position: absolute;
  top: unset;
  bottom: ${3 * GU}px;
  font-size: 20px;
  color: #8e97b5;
  opacity: 0.7;
`;
const StepsContainer = styled.div`
  padding: ${5 * GU}px ${2.5 * GU}px ${3 * GU}px ${3 * GU}px;
`;
