import React from 'react';
import { GU } from '@aragon/ui';
import { Row, Col } from '../../../../ui';
import { Header, AppSideBar, Footer } from '../../../index';
import { EnterPage } from '../../../../pages';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

export const AppContainer = ({ hideAppContainer = false, children }) => {
  const { userRole } = useSelector((state) => state.user);

  if (userRole) {
    return (
      <Container>
        <div>
          {!hideAppContainer && <Header />}

          <Row wrap={'nowrap'}>
            {!hideAppContainer && (userRole === 'admin' || userRole === 'superAdmin') && (
              <Col auto>
                <AppSideBar userRole={userRole} />
              </Col>
            )}

            <Col paddingLeft={2 * GU} paddingRight={3 * GU}>
              {children}
            </Col>
          </Row>
        </div>

        <Footer/>
      </Container>
    );
  }

  return null;
};

const Container = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
