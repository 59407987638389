import React, { useEffect, useRef } from 'react';
import { ToastHub, Toast as AragonToast } from '@aragon/ui';

const timeout = 3000;

export const Toast = ({ toast, removeToast }) => {
  const ref = useRef();

  useEffect(() => {
    if (toast) {
      try {
        ref.current.click();
        removeToast();
      } catch (e) {
        console.log(e);
      }
    }
  }, [toast]);

  return (
    <ToastHub timeout={timeout}>
      <AragonToast>{(addToast) => <div ref={ref} onClick={() => addToast(toast)} />}</AragonToast>
    </ToastHub>
  );
};
